// import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'
import HighlightPosts from '@/components/pages/blog/HighlightPosts.tsx'
import dynamic from 'next/dynamic'
import { useLangDetect } from '../hooks/useLangDetect'

const Header = dynamic(() => import('../components/layout/Header'), { ssr: false })

export default function DefaultLayout({ children, title, desc }) {
  const router = useRouter()
  const [isShowTopBanner, setIsShowTopBanner] = useState(router.pathname.includes('/blog'))

  useLangDetect()

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
      </Head>

      {router.pathname.includes('/blog') && isShowTopBanner ? (
        <div className="sticky top-0 z-20">
          <HighlightPosts onClose={() => setIsShowTopBanner(false)} />
        </div>
      ) : null}
      <Header sticky isShowTopBanner={isShowTopBanner} />
      {children}
      <Footer />
    </>
  )
}
