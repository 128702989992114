import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import dayjs from 'dayjs'
import Image from 'next/image'

export default function Footer() {
  const { t } = useTranslation('nav')

  const socialMedia = [
    {
      key: 'locker',
      url: 'https://github.com/orgs/lockerpm/discussions',
    },
    {
      key: 'facebook',
      url: 'https://www.facebook.com/lockerpm',
    },
    {
      key: 'twitter',
      url: 'https://twitter.com/lockerpm',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/showcase/lockerpm/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/lockerpasswordmanager/',
    },
    {
      key: 'youtube',
      url: 'https://www.youtube.com/@lockerpm',
    },
  ]

  const renderSection = ({ title, items }) => {
    return (
      <div>
        <p className="text-white text-md font-medium mb-3 uppercase">{title}</p>
        {items
          .filter((i) => i.url !== '#')
          .map((i, index) => {
            const className = `text-md transition-colors ${i.highlight ? 'font-semibold text-warning hover:text-warning-500' : 'hover:text-white text-white-secondary'} ${index < items.length - 1 ? 'mb-2' : 'mb-8'}`
            if (i.action) {
              switch (i.action) {
                case 'chatwoot': {
                  return (
                    <a
                      key={index}
                      href="#chat"
                      onClick={(e) => {
                        e.preventDefault()
                        window.$chatwoot.toggle('open')
                      }}
                    >
                      <p className={className}>{i.title}</p>
                    </a>
                  )
                }
              }
              return
            }

            return (
              <Link target={i.external ? '_blank' : '_self'} key={index} href={i.url}>
                <p className={className}>{i.title}</p>
              </Link>
            )
          })}
      </div>
    )
  }

  return (
    <footer id="footer" className="bg-black">
      <div className="section-container py-20 flex flex-wrap">
        {/* Info */}
        <div className="lg:w-4/12 w-full mb-16 lg:mb-0">
          <div
            className="w-full"
            style={{
              maxWidth: 291,
            }}
          >
            <Image
              src="/images/logo/white.svg"
              className="mb-4 h-14 w-auto"
              alt="Locker logo"
              height={56}
              width={164}
            />
            <div className="grid grid-cols-6 gap-3 mb-12 w-full">
              {socialMedia.map((item) => (
                <a target="_blank" href={item.url} key={item.key}>
                  <Image
                    src={`/images/nav/social/${item.key}.svg`}
                    className="h-8 w-8 hover:scale-125 transition-transform"
                    alt={item.key}
                    height={32}
                    width={32}
                  />
                </a>
              ))}
            </div>
          </div>

          <div>
            <p className="text-md text-white-secondary mb-2">
              {t('info.address.title')}:{' '}
              <a
                href="https://maps.app.goo.gl/d6kb7n55xHozACB36"
                className="hover:text-white"
                target="_blank"
              >
                {t('info.address.desc')}
              </a>
            </p>
            <p className="text-md text-white-secondary mb-2">
              Email:{' '}
              <a href="mailto:contact@locker.io" className="hover:text-white">
                contact@locker.io
              </a>
            </p>
            <p className="text-md text-white-secondary">
              {t('info.phone')}:{' '}
              <a href="tel:+842471099656" className="hover:text-white">
                (+84) 247 109 9656
              </a>
            </p>
          </div>
          <div className="flex gap-5 mt-12">
            <div className="flex flex-col items-center gap-2">
              <Image
                src={'/images/Iso2015.svg'}
                width={82}
                height={80}
                alt="Locker ISO 9001:2015"
              />
              <span className="text-black uppercase text-sm font-bold bg-white bg-opacity-70 text-center py-[4px] inline-block w-full">
                certified
              </span>
            </div>
            <div className="flex flex-col items-center gap-2">
              <Image
                src={'/images/Iso2022.svg'}
                width={86}
                height={80}
                alt="Locker ISO 9001:2015"
              />
              <span className="text-black uppercase text-sm font-bold bg-white bg-opacity-70 text-center py-[4px] inline-block w-full">
                certified
              </span>
            </div>
          </div>
          <div className="flex gap-5 mt-8 sm:flex-row flex-col">
            <Image src={'/images/GDPR.svg'} width={176} height={62} alt="Locker GDPR compliant" />
            <Image src={'/images/CCPA.svg'} width={176} height={62} alt="Locker CCPA compliant" />
          </div>
        </div>
        {/* Info end */}

        <div className="w-1/12"></div>

        {/* Nav */}
        <div className="lg:w-7/12 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <div>
            {renderSection(t('products', { returnObjects: true }))}
            {renderSection(t('download', { returnObjects: true }))}
            {renderSection(t('free_tools', { returnObjects: true }))}
          </div>

          <div>{renderSection(t('resources', { returnObjects: true }))}</div>

          <div>
            {renderSection(t('support', { returnObjects: true }))}
            {renderSection(t('about_us', { returnObjects: true }))}
          </div>
        </div>
        {/* Nav end */}
      </div>

      <div className="section-container">
        {/* Bottom */}
        <div className="flex justify-between py-4 flex-wrap">
          <div className="md:w-1/2 w-full">
            <p className="text-secondary-45 text-sm py-2">
              © {dayjs().year()} by CyStack.,JSC. All rights reserved.
            </p>
          </div>

          <div className="md:w-1/2 w-full flex md:justify-end py-2">
            {t('bottom.items', { returnObjects: true }).map((item, index) => (
              <Link
                target="_blank"
                key={index}
                href={item.url}
                className={index !== 0 ? 'ml-6' : ''}
              >
                <p className="text-secondary-45 hover:text-white text-sm transition-colors">
                  {item.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
        {/* Bottom end */}
      </div>
    </footer>
  )
}
