import IconClose from '@/public/icons/Close.svg'
import IconStars from '@/public/icons/stars.svg'
import { useEffect, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { NewsItem } from 'src/pages/api/v1/news'
import { Tooltip, Carousel } from '@lockerpm/design'
import Link from 'next/link'

export default function HighlightPosts({ onClose }) {
  const { t, i18n } = useTranslation('nav')

  // -------------------- PARAMS --------------------

  const [news, setNews] = useState<NewsItem[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const sliderRef = useRef(null)

  // -------------------- COMPUTED --------------------

  const visibleNews = useMemo(() => {
    return news.filter((data: NewsItem) => {
      if (data?.Status !== 'Active') {
        return false
      }
      return (
        ((i18n.language === 'vi' && data?.Language === 'Vietnamese') ||
          (i18n.language === 'en' && data?.Language === 'English')) &&
        data?.Link
      )
    })
  }, [news, i18n.language])

  // -------------------- METHODS --------------------

  const loadNews = async () => {
    try {
      const res = await fetch('/api/v1/news')
      const json = await res.json()
      setNews(json.data)
    } catch (error) {
      console.error(error)
    }
  }

  // -------------------- EFFECTS --------------------

  useEffect(() => {
    loadNews()
  }, [])

  // -------------------- RENDER --------------------

  return visibleNews.length ? (
    <div className="bg-[#079455] py-3 h-16 flex flex-col justify-center">
      <div className="section-container">
        <div className="flex items-center">
          {visibleNews.length > 1 && (
            <div className="md:mr-4 mr-2 flex flex-col gap-1">
              <Image
                src="/icons/CaretUp.svg"
                alt="Next news"
                className="cursor-pointer w-4"
                width={16}
                height={16}
                onClick={() => {
                  sliderRef.current?.next()
                }}
              />
              <Image
                src="/icons/CaretDown.svg"
                alt="Prev news"
                width={16}
                height={16}
                className="cursor-pointer w-4"
                onClick={() => {
                  sliderRef.current?.prev()
                }}
              />
            </div>
          )}

          <div className="flex-1 overflow-hidden">
            <Carousel
              ref={sliderRef}
              dots={false}
              arrows={false}
              infinite
              vertical
              pauseOnHover
              autoplay={false}
              autoplaySpeed={4000}
              slidesToShow={1}
              slidesToScroll={1}
              afterChange={setCurrentIndex}
            >
              {visibleNews.map((item) => (
                <Link key={item.id} href={item.Link.trim()} target="_blank">
                  <Tooltip title={item.Text}>
                    <div className="flex items-center">
                      <div className="sm:flex hidden items-center text-white font-semibold bg-[#067647] py-1 px-3 text-md rounded-full">
                        <IconStars className="w-4 mr-1" />
                        <span>New</span>
                      </div>
                      <p className="text-white text-md font-semibold mx-3 line-clamp-1 mb-0">
                        {item.Text}
                      </p>
                    </div>
                  </Tooltip>
                </Link>
              ))}
            </Carousel>
          </div>

          <Link
            href={visibleNews[Math.min(currentIndex, visibleNews.length - 1)]?.Link}
            target="_blank"
            className="md:flex hidden font-semibold text-white mr-4"
          >
            {t('view_detail')}
          </Link>

          <div className="flex items-center justify-center p-[10px]" onClick={onClose}>
            <IconClose className="cursor-pointer text-white w-5 h-5" />
          </div>
        </div>
      </div>
    </div>
  ) : null
}
