import { useRouter } from 'next/router'
import { setCookie, hasCookie } from 'cookies-next'
import { useEffect } from 'react'

export const useLangDetect = () => {
  const router = useRouter()

  const detectAndRedirect = async () => {
    const isEn = router.locale === 'en'
    const isVisited = hasCookie('visited')

    if (isEn && !isVisited) {
      setCookie('visited', '1', { maxAge: 60 * 60 * 24 * 7 })
      try {
        const res = await fetch(`https://api.cystack.net/v3/resources/lang/resources/lang`)
        const data = await res.json()
        const lang = data.language
        if (lang === 'vi') {
          router.push(
            {
              // @ts-ignore
              route: router.pathname,
              query: router.query,
            },
            router.asPath,
            { locale: lang }
          )
        }
      } catch (error) {
        console.log('Cannot detect lang', error)
      }
    }
  }

  useEffect(() => {
    detectAndRedirect()
  }, [])
}
